import type { DataLoadStatus } from 'src/interface/command-center/unsorted-types';
import { Interpolations } from '@motional-cc/fe/tools/translate';
import clsx from 'clsx';
import { ReactNode } from 'react';
import EmptyState, {
  EMPTY_STATE_DESCRIPTION_CLASS,
} from 'src/components/common/EmptyState';
import { ApiError } from 'src/interface/command-center/unsorted-classes';
import { isIncompleteStatus } from 'src/interface/command-center/unsorted-type-guards';
import {
  DEFAULT_ERROR_BRIEF,
  renderErrorBrief,
  renderErrorDetails,
} from 'src/tools/http/renderErrorMessaging';
import { isNotNullish } from 'src/tools/types';
import FullWidthLoader from './FullWidthLoader';
import './LoadingErrorEmptyState.scss';

const defaultRenderErrorDetails = <ErrorObject extends ApiError | Error>(
  error: ErrorObject,
  errorPayload: Interpolations,
) => {
  const errors = renderErrorDetails(error, { payload: errorPayload });

  if (Array.isArray(errors)) {
    // TODO: work out how to remove `as readonly string[]`
    return (errors as readonly string[]).map((error, i) => (
      <p key={`${error}--${i}`} className={EMPTY_STATE_DESCRIPTION_CLASS}>
        {error}
      </p>
    ));
  } else {
    return errors;
  }
};

interface Props<Result, ErrorObject extends ApiError | Error> {
  className?: string;
  children?: (result: Result) => ReactNode;
  status: DataLoadStatus;
  result?: Result | null | undefined;
  error?: ErrorObject | null | undefined;
  errorPayload?: Interpolations;
  renderErrorTitle?: ((error: ErrorObject) => string) | undefined;
  renderErrorDetails?:
    | ((error: ErrorObject, payload?: Interpolations) => ReactNode)
    | undefined;
  loadingTitle?: string;
  renderLoadingState?: (() => ReactNode) | undefined;
  emptyTitle?: string;
  emptyDetails?: string | undefined;
  hideEmptyState?: boolean;
}

function LoadingErrorEmptyState<Result, ErrorObject extends ApiError | Error>({
  className,
  children,
  status,
  result,
  error,
  errorPayload,
  renderErrorTitle = renderErrorBrief,
  renderErrorDetails = defaultRenderErrorDetails,
  emptyTitle,
  emptyDetails,
  hideEmptyState,
  loadingTitle,
  renderLoadingState,
}: Props<Result, ErrorObject>) {
  if (status === 'error') {
    const messaging =
      error ?
        ({
          title: renderErrorTitle(error),
          description: renderErrorDetails(error, errorPayload),
        } as Pick<Parameters<typeof EmptyState>[0], 'title' | 'description'>)
      : ({
          title: DEFAULT_ERROR_BRIEF,
        } as Pick<Parameters<typeof EmptyState>[0], 'title'>);

    if (error)
      return (
        <EmptyState
          className="loading-error-empty-state__empty-state"
          {...messaging}
        />
      );
  }

  return (
    isIncompleteStatus(status) ?
      renderLoadingState?.() ||
        (loadingTitle ?
          <EmptyState
            title={loadingTitle}
            description={
              <FullWidthLoader
                className={clsx([
                  className,
                  'loading-error-empty-state__loader',
                ])}
              />
            }
          />
        : <FullWidthLoader
            className={clsx([className, 'loading-error-empty-state__loader'])}
          />)
    : !children ? null
    : isNotNullish(result) && (!Array.isArray(result) || result.length > 0) ?
      <>{children?.(result)}</>
    : !children || hideEmptyState ? null
    : <EmptyState
        className={clsx([className, 'loading-error-empty-state__empty-state'])}
        title={emptyTitle || 'No data found'}
        description={emptyDetails}
      />
  );
}

export default LoadingErrorEmptyState;
